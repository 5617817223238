import 'core-js/es6/reflect';
import 'core-js/es7/reflect';
import 'zone.js/dist/zone';

declare global {
  interface Window {
    process: any;
    Buffer: any;
  }
}

(window as any).global = window;
window.process = {
  env: { DEBUG: undefined },
};
window.Buffer = window.Buffer || require('buffer/').Buffer;
